import i18n from "../i18n";

const DeliveredStatuses = {
  0: {
    name: i18n.global.t("deliveredStatuses.waiting"),
    color: "badge-outline",
    id: 0,
    icon: "tir",
  },
  1: {
    name: i18n.global.t("deliveredStatuses.underPackaging"),
    color: "bg-info text-body",
    id: 1,
    icon: "tir",
  },
  2: {
    name: i18n.global.t("deliveredStatuses.waitForDelivery"),
    color: "bg-info text-body",
    id: 2,
    icon: "tir",
  },
  3: {
    name: i18n.global.t("deliveredStatuses.underDelivery"),
    color: "bg-primary text-white",
    id: 3,
    icon: "delivery-info",
  },
  4: {
    name: i18n.global.t("deliveredStatuses.onHold"),
    color: "bg-warning text-dark",
    id: 4,
    icon: "truck-time",
  },
  5: {
    name: i18n.global.t("deliveredStatuses.delivered"),
    color: "bg-secondary text-white",
    id: 5,
    icon: "truck-tick",
  },
  8: {
    name: i18n.global.t("deliveredStatuses.waitForPickup"),
    color: "bg-primary text-white",
    id: 8,
    icon: "truck-time",
  },
  6: {
    name: i18n.global.t("deliveredStatuses.successful"),
    color: "bg-success text-white",
    id: 6,
    icon: "truck-tick",
  },
  7: {
    name: i18n.global.t("deliveredStatuses.failed"),
    color: "bg-danger text-white",
    id: 7,
    icon: "truck-remove",
  },
};

export default DeliveredStatuses;
