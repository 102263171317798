import i18n from "../i18n";

const OrderStatuses = {
  "-2": {
    id: -2,
    name: i18n.global.t("orderStatuses.preorder"),
    color: "badge-outline-primary text-black",
  },
  "-1": {
    id: -1,
    name: i18n.global.t("orderStatuses.notFinished"),
    color: "warning text-black",
  },
  0: {
    id: 0,
    name: i18n.global.t("orderStatuses.waiting"),
    color: "badge-outline text-black",
  },
  1: {
    id: 1,
    name: i18n.global.t("orderStatuses.underProcess"),
    color: "info text-white",
  },
  2: {
    id: 2,
    name: i18n.global.t("orderStatuses.successful"),
    color: "successful text-white",
  },
  3: {
    id: 3,
    name: i18n.global.t("orderStatuses.failed"),
    color: "failed text-white",
  },
};

export default OrderStatuses;
