<template>
  <div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="nature-white-box">
          <div class="white-box-top"></div>
          <div class="row">
            <div class="col-3">
              <h2 class="mb-3">{{ $t("messages.messages") }}</h2>
              <button
                class="message"
                v-for="message in messages"
                :key="message.id"
                v-on:click="selectedMessage = message"
              >
                <div class="row">
                  <div class="col bold-14">
                    {{message.contactCategory?.name ?? "-"}}
                  </div>
                  <div class="col-auto regular-14">
                    {{formatDate(message.created_at) ?? "-"}}
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    {{message.text.substr(0,80)}}
                  </div>
                  <div class="col-auto">
                    {{message.order?.pretty_id ?? "-"}}
                  </div>
                </div>
              </button>
            </div>
            <div class="col-7 my-3" v-if="selectedMessage">
              <div class="row">
                <div class="col bold-16">
                  {{selectedMessage.contactCategory?.name ?? "-"}}
                </div>
                <div class="col-auto regular-16">
                  {{formatDate(selectedMessage.created_at) ?? "-"}}
                </div>
              </div>
              <div class="row" v-if="selectedMessage.order">
                <div class="col-auto bold-16">
                  {{selectedMessage.order?.email ?? "-"}}
                </div>
                <div class="col-auto regular-16">
                  {{selectedMessage.order?.phone ?? "-"}}
                </div>
              </div>
              {{selectedMessage.text}}
            </div>
            <div class="col-2" v-if="selectedMessage && selectedMessage.order">
              <div
                  v-if="selectedMessage.order.status"
                  class="white-box-little text-center"
                  :class="statuses[selectedMessage.order.status].color"
              >
                <div class="text-area">
                  <BaseIcon name="bag" class="my-2 d-block" />
                  <h2 class="bold-12 mb-3">
                    {{ statuses[selectedMessage.order.status].name }}
                  </h2>
                </div>
              </div>
              <div
                  v-if="selectedMessage.order.payment_status"
                  class="white-box-little text-center"
                  :class="paymentStatuses[selectedMessage.order.payment_status].color"
              >
                <div class="text-area">
                  <BaseIcon
                      :name="paymentStatuses[selectedMessage.order.payment_status].icon"
                      class="my-2 d-block"
                  />
                  <h2 class="bold-12 mb-3">
                    {{ paymentStatuses[selectedMessage.order.payment_status].name }}
                  </h2>
                </div>
              </div>
              <div
                  v-if="selectedMessage.order.delivered_status"
                  class="white-box-little text-center"
                  :class="deliveredStatuses[selectedMessage.order.delivered_status].color"
              >
                <div class="text-area">
                  <BaseIcon
                      :name="deliveredStatuses[selectedMessage.order.delivered_status].icon"
                      class="my-2 d-block"
                  />
                  <h2 class="bold-12 mb-3">
                    {{ deliveredStatuses[selectedMessage.order.delivered_status].name }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import ws from "@/modules/ws";
import http from "@/modules/http";
import date from "@/modules/date";
import deliveredStatuses from "../../modules/DeliveredStatuses";
import paymentStatuses from "../../modules/PaymentStatuses";
import orderStatuses from "../../modules/OrderStatuses";
import BaseIcon from "../../components/icons/BaseIcon";

export default {
  name: "Messages",
  components: {BaseIcon},
  data() {
    return {
      deliveredStatuses: deliveredStatuses,
      paymentStatuses: paymentStatuses,
      statuses: orderStatuses,
      store: useStore(),
      page: 1,
      messages: [],
      categories: [],
      selectedMessage: null,
    };
  },
  computed: {
    warehouses() {
      return this.store.state.warehouses;
    },
    path() {
      return this.$route.path;
    },
  },
  mounted() {
    this.loadData();

    ws.init();

    ws.subscribe("add", "contactMessage", (e) => {
      if (e.session !== this.sessionId) {
        this.messages.push(e.object);
        this.tableKey++;
      }
    });

  },
  methods: {
    formatDate(val) {
      return date.format(val, true, false);
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    loadData() {
      let url = `/messages?page=${this.page}`;
      http.fetch(url).then((data) => {
        if (this.page === 1) {
          this.messages = data.data;
          this.categories = data.categories;
        } else {
          for (const row of data.data) {
            this.messages.push(row);
          }
        }
      });
    },
  },
};
</script>
