import i18n from "../i18n";

const PaymentStatuses = {
  1: {
    id: 1,
    name: i18n.global.t("paymentStatuses.notPayed"),
    color: "warning text-black",
    icon: "wallet-empty",
  },
  2: {
    id: 2,
    name: i18n.global.t("paymentStatuses.payed"),
    color: "successful text-white",
    icon: "wallet-money",
  },
  3: {
    id: 3,
    name: i18n.global.t("paymentStatuses.partlyPayed"),
    color: "light text-white",
    icon: "wallet-time",
  },
  4: {
    id: 4,
    name: i18n.global.t("paymentStatuses.refund"),
    color: "info text-white",
    icon: "wallet-change",
  },
  5: {
    id: 5,
    name: i18n.global.t("paymentStatuses.partlyRefund"),
    color: "light text-white",
    icon: "wallet-change",
  },
  6: {
    id: 6,
    name: i18n.global.t("paymentStatuses.waitForRefund"),
    color: "primary text-white",
    icon: "wallet-change",
  },
  7: {
    id: 7,
    name: i18n.global.t("paymentStatuses.failed"),
    color: "failed text-white",
    icon: "wallet-empty",
  },
  8: {
    id: 8,
    name: i18n.global.t("paymentStatuses.needManualRefund"),
    color: "failed text-white",
    icon: "wallet-change",
  },
  40: {
    id: 40,
    name: i18n.global.t("paymentStatuses.pending"),
    color: "badge-outline text-white",
    icon: "wallet-time",
  },
};

export default PaymentStatuses;
